import React from "react";
import CreateAccount from "../../components/auth-component/CreateAccount";



const SignUp = () => {
  return <CreateAccount />;
};

export default SignUp;
