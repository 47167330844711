import React from "react";

const Search = () => {
  return (

      <div className="flex items-center justify-center h-full w-full">
        <span style={{ textAlign: "center" }}>
          {" "}
          No criteria matches the overload
        </span>
      </div>

  );
};

export default Search;
